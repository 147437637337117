import React from 'react';
import Footer from '../comps/Footer';
import { Box } from '@mui/material';
import MasonryGallery from '../comps/MasonryGallery';
import BasicNavbar from '../comps/BasicNavbar';


function HomePage() {
  return <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
  <BasicNavbar />
  <Box sx={{ flex: 1 }}> {/* Content expands to fill available space */}
    <MasonryGallery />
    
  </Box>
  <Footer />
</Box>;
}

export default HomePage;
