import React from 'react';
import { Box, Typography, Grid, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function Footer() {
    const { t } = useTranslation();
    const navigate = useNavigate();
  return (
    <Box sx={{ backgroundColor: '#2a2a2a', color: 'white', padding: 3 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="h6">{t("name")}</Typography>
          <Typography variant="body2" sx={{ mt: 1 }}>
            Leading the skies with innovative drone solutions.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="h6">{t("quicklinks")}</Typography>
          <Link href="#" color="inherit" sx={{ display: 'block', mt: 1 }}>Home</Link>
          <Link href="/gallery" color="inherit" sx={{ display: 'block' }}>{t('Gallery')}</Link>
          <Link href="#" color="inherit" sx={{ display: 'block' }} onClick={
            (e) => {
              e.preventDefault();
              navigate('/', { state: { scrollToContact: true } });
              const contactForm = document.getElementById('contact-form');
              if (contactForm) {
                  contactForm.scrollIntoView({ behavior: 'smooth' });
              }
            }
          }>Contact</Link>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="h6">{t("contactus")}</Typography>
          <Typography variant="body2" sx={{ mt: 1 }}>info@clouro.be</Typography>
          <Typography variant="body2">+32 472 93 46 98</Typography>
        </Grid>
      </Grid>
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <Typography variant="body2">
          © {new Date().getFullYear()} {t("name")}. {t("allrightsreserved")}.
        </Typography>
      </Box>
    </Box>
  );
}

export default Footer;