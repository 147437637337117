import React, {useEffect} from 'react';
import NavBar from '../comps/NavBar';
import HeroSection from '../comps/HeroSection';
import Footer from '../comps/Footer';
import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import ContactForm from '../comps/ContactForm';
import ServicesSection from '../comps/ServicesSection';
import AboutSection from '../comps/AboutSection';

function HomePage() {

  const location = useLocation();

    useEffect(() => {
        if (location.state?.scrollToContact) {
            const contactForm = document.getElementById('contact-form');
            if (contactForm) {
                contactForm.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);


  return <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
  <NavBar hasBackground={false}/>
  <Box sx={{ flex: 1 }}> {/* Content expands to fill available space */}
    <HeroSection />

    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <AboutSection />
    </Box>
    <ServicesSection />
    
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <ContactForm />
    </Box>
    {/* <TestimonialsSection /> */}
    {/* Include other sections here */}
    {/* <ServicesAndContactPage /> */}
    
  </Box>
  <Footer />
</Box>;
}

export default HomePage;
