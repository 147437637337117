import React from 'react';
import { Grid, Box, Typography, Card, useTheme, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';

function ServiceCard({ title, description, image }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  
  const cardStyle = {
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row', // Stack elements vertically on mobile
    alignItems: 'center',
    justifyContent: 'start',
    borderRadius: '8px',
    overflow: 'hidden',
    boxShadow: '0px 6px 18px rgba(0, 0, 0, 0.12)',
    height: isMobile ? 'auto' : '150px', // Auto height on mobile for content fit
    backgroundColor: '#ffffff', // or use brand-specific color
  };

  const imageStyle = {
    width: isMobile ? '100%' : '150px', // Full width on mobile
    height: isMobile ? '150px' : '100%', // Fixed height for image on mobile
    objectFit: 'cover',
  };

  const contentStyle = {
    padding: isMobile ? '16px' : '0 24px', // Reduced padding on mobile
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexGrow: 1,
  };

  return (
    <Card sx={cardStyle}>
      <Box component="img" src={image} sx={imageStyle} />
      <Box sx={contentStyle}>
        <Typography variant="h6" component="h3" gutterBottom sx={{ fontSize: isMobile ? '1rem' : '1.25rem' }}>
          {title}
        </Typography>
        <Typography variant="body2" sx={{ fontSize: isMobile ? '0.875rem' : '1rem' }}>
          {description}
        </Typography>
      </Box>
    </Card>
  );
}

function ServicesSection() {
  const { t } = useTranslation();
  
  const services = [
    { title: t('aerialPhotographyTitle'), description: t('aerialPhotographyDesc'), image: '/foto2.webp' },
    { title: t('immoPictures'), description: t('immoPicturesDesc'), image: '/gallery/gallerij5.webp' },
    { title: t('droneMappingTitle'), description: t('droneMappingDesc'), image: '/gallery/gallerij1.webp' },
    { title: t('droneInspectionTitle'), description: t('droneInspectionDesc'), image: '/fotoRoofinspectionCompressed.webp' },
    { title: t('aftermovieTitle'), description: t('aftermovieDesc'), image: '/gallery/gallerij7.webp' },
    { title: t('insuranceTitle'), description: t('insuranceDesc'), image: '/fotoInsuranceCompressed.webp' },
    // Add more services as needed
  ];

  return (
    <Box sx={{ flexGrow: 1, padding: 4 }} id="services">
      <Grid container spacing={4}>
        {services.map((service, index) => (
          <Grid item xs={12} sm={6} md={6} lg={6} xl={4} key={index}>
            <ServiceCard 
              title={service.title} 
              description={service.description} 
              image={service.image}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default ServicesSection;
