import React from 'react';
import { AppBar, Toolbar, Button, Grid, useMediaQuery, useTheme } from '@mui/material';
import Logo from './Logo';
import LanguageSwitcher from './LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const BasicNavbar = React.forwardRef((props, ref) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <AppBar position="sticky" style={{ background: '#333', boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2)', color: 'white', padding: "0.5rem 0" }}>
            <Toolbar>
                <Grid container alignItems="center" justifyContent="center">
                    {/* Left-aligned items (e.g., Logo) */}
                    {isMobile && (
                        <Grid item xs={2} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <Logo size='50px'/>
                        </Grid>
                    )}

                    {/* Center-aligned items (e.g., Navigation Buttons) */}
                    <Grid item xs={5} sm={5} md={5} lg={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button color="inherit" onClick={() => navigate('/')}>Home</Button>
                        <Button color="inherit" onClick={() => navigate('/gallery')}>{t("gallery")}</Button>
                    </Grid>

                    {/* Center Logo for larger screens */}
                    <Grid item xs={2} sm={2} md={2} lg={2} style={{ textAlign: 'center', display: isMobile ? 'none' : 'block' }}>
                        <Logo size="125px"/>
                    </Grid>

                    {/* Right-aligned items (e.g., Contact, LanguageSwitcher) */}
                    <Grid item xs={5} sm={5} md={5} lg={5} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Button color="inherit" onClick= {
                            () => {
                                navigate('/', { state: { scrollToContact: true } });
                            }
                        }>Contact</Button>
                        <LanguageSwitcher />
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
});

export default BasicNavbar;
