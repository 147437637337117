import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import your translation files
import translationEN from './locales/en/translation.json';
import translationNL from './locales/nl/translation.json';
import translationFR from './locales/fr/translation.json';

// the translations
const resources = {
  en: {
    translation: translationEN
  },
  nl: {
    translation: translationNL
  },
  fr: {
    translation: translationFR
  }
};

i18n
  // Pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // Use the custom LanguageDetector.
  .use(new LanguageDetector(null, {
    // Order and from where user language should be detected.
    order: ['path', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'subdomain'],
    
    // Options for lookup from the path.
    lookupFromPathIndex: 0,
    
    // Options for cookie.
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',

    // Cache user language on.
    caches: ['localStorage', 'cookie'],
    excludeCacheFor: ['cimode'], // Languages to not persist.
    
    // Only detect languages that are in the whitelist.
    checkWhitelist: true,

    // Override the detected language with the base language.
    lookup() {
      const detectedLng = window.navigator.userLanguage || window.navigator.language;
      const baseLng = detectedLng.split('-')[0]; // Take the base language (en from en-US)
      
      // Check if the base language is part of the supported languages, otherwise fall back to default.
      if (resources[baseLng]) {
        return baseLng;
      }
      return 'nl'; // Default language
    }
  }))
  .init({
    resources,
    fallbackLng: 'nl', // Default fallback language.
    whitelist: ['en', 'nl'],
    nonExplicitSupportedLngs: true, // Load base language if a regional variant is detected.
    load: 'languageOnly', // Load only language without region code.
    debug: false, // Set to true if you want to see logs for debugging.

    interpolation: {
      escapeValue: false // Not needed for React as it escapes by default.
    }
  });

export default i18n;
