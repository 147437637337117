import React from 'react';
import { AppBar, Toolbar, Button, Grid, useMediaQuery, useTheme } from '@mui/material';
import Logo from './Logo';
import LanguageSwitcher from './LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';


const Navbar = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <AppBar position="absolute" style={{ background: 'transparent', boxShadow: 'none', marginTop: "1rem"}}>
            <Toolbar>
                <Grid container alignItems="center" justifyContent="center">

                {isMobile && (
                        <Grid item xs={2} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <Logo size='50px'/>
                        </Grid>
                    )}

                    <Grid item xs={5} sm={5} md={5} lg={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button color="inherit">Home</Button>
                        <Button color="inherit" onClick={
                            () => {
                                navigate('/gallery');
                            }
                        }>{t("gallery")}</Button>
                    </Grid>

                    {/* Conditionally render Logo based on screen size */}
                    <Grid item xs={2} sm={2} md={2} lg={2} style={{ textAlign: 'center', display: isMobile ? 'none' : 'block' }}>
                        <Logo size="125px"/>
                    </Grid>

                    <Grid item xs={5} sm={5} md={5} lg={5} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <Button color="inherit" onClick= {
                            () => {
                                navigate('/');
                                const contactForm = document.getElementById('contact-form');
                                if (contactForm) {
                                    contactForm.scrollIntoView({ behavior: 'smooth' });
                                }
                            }
                        }>Contact</Button>
                        <LanguageSwitcher />
                    </Grid>

                    {/* Render Logo on the left for mobile screens */}
                    
                </Grid>
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;
