import React, { useState } from 'react';
import { Box, TextField, Button, Grid, Typography, MenuItem, Select, InputLabel, FormControl, useTheme, useMediaQuery } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useTranslation } from 'react-i18next';

function ContactForm() {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [submitted, setSubmitted] = useState(false); // New state for tracking submission


  const [contact, setContact] = useState({
    name: '',
    email: '',
    message: '',
    topic: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setContact({ ...contact, [name]: value });
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    const myForm = event.target;
    const formData = new FormData(myForm);

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        setSubmitted(true); // Update the state to indicate successful submission
        // You may want to reset the form here
      })
      .catch((error) => alert(error));
  };

  return (
    <Box sx={{ maxWidth: '100%', padding: '2rem', bgcolor: 'background.paper', margin: isMobile ? '1rem 0' : '2rem 1rem', boxShadow: 3 }}>
      <Typography variant="h4" align="center" gutterBottom>
        {t("ContactUs")}
      </Typography>
      
      {/* This hidden form is necessary for Netlify to recognize your React form */}
      
      
      {/* Your visible form */}
      <form name="contact" method="post" onSubmit={handleSubmit} id="contact-form">
        {/* The `name` attribute value should match the name of your Netlify form */}
        <input type="hidden" name="form-name" value="contact" />
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="name"
              label={t("YourName")}
              value={contact.name}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="email"
              label={t("YourEmail")}
              value={contact.email}
              onChange={handleChange}
              required
              type="email"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="topic-label">{t("SelectTopic")}</InputLabel>
              <Select
                labelId="topic-label"
                name="topic"
                value={contact.topic}
                label={t("SelectTopic")}
                onChange={handleChange}
                required
              >
                <MenuItem value="RequestQuote">{t("RequestQuote")}</MenuItem>
                <MenuItem value="GeneralInfo">{t("GeneralInfo")}</MenuItem>
                <MenuItem value="Other">{t("Other")}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="message"
              label={t("YourMessage")}
              value={contact.message}
              onChange={handleChange}
              required
              multiline
              rows={4}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              endIcon={<SendIcon />}
              fullWidth
            >
              {t("SendMessage")}
            </Button>
          </Grid>
        </Grid>
      </form>
      {/* Confirmation message */}
      {submitted && (
        <Typography variant="h6" style={{ color: 'green', marginTop: '20px', textAlign: 'center' }}>
          {t("FormSubmittedMessage")} {/* Add a translation key for the success message */}
        </Typography>
      )}
    </Box>
  );
}

export default ContactForm;
