import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';


// Dummy data for gallery images
const galleryImages = [
  
  
  { src: '/gallery/gallerij1.webp', caption: 'Aalst' },
  { src: '/gallery/gallerij2.webp', caption: 'Rondpunt Haring - Aalst' },
  { src: '/foto3.webp', caption: 'Miata - Golden Hour' },
  { src: '/gallery/gallerij3.webp', caption: 'Affligem' },
  { src: '/foto1.webp', caption: 'Kust van Antibes - Frankrijk' },
  { src: '/gallery/gallerij4.webp', caption: 'Miata - Golden Hour' },
  { src: '/gallery/gallerij5.webp', caption: 'Deinze' },
  { src: '/foto2.webp', caption: 'Deinze' },
  // ... more images
];
const HoverCard = styled(Card)(({ theme }) => ({
    position: 'relative',
    '&:hover .caption': {
      opacity: 1,
    },
    margin: theme.spacing(1),
  }));
  
  const Caption = styled(Typography)(({ theme }) => ({
    position: 'absolute',
    bottom: 0,
    width: '100%',
    textAlign: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    padding: theme.spacing(1),
    opacity: 0,
    transition: 'opacity 0.3s ease',
  }));
  
  const ModalContainer = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '90vw',
    maxHeight: '90vh',
    display: 'flex',
    flexDirection: 'column',
    bgcolor: 'background.paper',
    boxShadow: theme.shadows[5],
  }));
  
  const ModalImage = styled('img')({
    maxHeight: '80vh',
    maxWidth: '100%',
    objectFit: 'contain',
  });
  
  const ModalCaption = styled(Typography)(({ theme }) => ({
    mt: 2,
    p: 1,
    textAlign: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
  }));
  
  const MasonryGallery = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState({ src: '', caption: '' });
  
    const handleOpen = (image) => {
      setSelectedImage(image);
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
    return (
      <>
        <Box sx={{
          display: 'grid',
          gridTemplateColumns: isMobile ? '1fr' : 'repeat(auto-fill, minmax(30%, 1fr))',
          gridGap: theme.spacing(2),
          padding: theme.spacing(2),
        }}>
          {galleryImages.map((image, index) => (
            <HoverCard key={index} onClick={() => handleOpen(image)}>
              <CardMedia
                component="img"
                image={image.src}
                alt={image.caption}
                decoding='async'
                loading='lazy'
                sx={{
                  width: '100%',
                  height: 'auto',
                }}
              />
              <Caption className="caption">{image.caption}</Caption>
            </HoverCard>
          ))}
        </Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ModalContainer sx={{
            width: isMobile ? '90vw' : 'auto', // Full width on mobile
            height: 'auto', // Full height on mobile
          }}>
            <IconButton
              onClick={handleClose}
              sx={{
                position: 'absolute',
                top: theme.spacing(1),
                right: theme.spacing(1),
                color: 'common.white',
                zIndex: 1,
              }}
            >
              <CloseIcon />
            </IconButton>
            <ModalImage src={selectedImage.src} alt={selectedImage.caption} />
            <ModalCaption>{selectedImage.caption}</ModalCaption>
          </ModalContainer>
        </Modal>
      </>
    );
  };
  
  export default MasonryGallery;