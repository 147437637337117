import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Select, MenuItem } from '@mui/material';

function LanguageSwitcher() {
    const { i18n } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

    useEffect(() => {
        // This effect will run once after the initial render and
        // anytime the i18n.language changes.
        setSelectedLanguage(i18n.language);
    }, [i18n.language]);

    const changeLanguage = (event) => {
        const newLanguage = event.target.value;
        setSelectedLanguage(newLanguage); // Set new language as selected
        i18n.changeLanguage(newLanguage); // Tell i18next to change the language
    };
  
    return (
      <Select
        value={selectedLanguage.split('-')[0]} // Simplify the language code
        onChange={changeLanguage}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        style={{ color: '#fff', fontWeight: 'bold' }}
        sx={{
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 'none'
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            border: 'none'
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
          },
          '& .MuiSelect-select': {
            paddingRight: '24px' // Adjust the padding to account for the icon
          }
        }}
      >
        <MenuItem value="en">EN</MenuItem>
        <MenuItem value="nl">NL</MenuItem>
        {/* <MenuItem value="fr">FR</MenuItem> */}
      </Select>
    );
  }
  
  export default LanguageSwitcher;