import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay, EffectFade } from 'swiper/modules';

import 'swiper/css';

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';

import { Box, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';


function HeroSection() {
  const images = [
    "/foto1.webp",
    "/foto2.webp",
    "/foto3.webp",
    // Add more image paths as needed
  ];

  const { t } = useTranslation();

  return (
    <Box sx={{ position: 'relative', height: '100vh' }}>
      <Swiper
        modules={[Pagination, Navigation, Autoplay, EffectFade]}
        spaceBetween={0}
        slidesPerView={1}
        pagination={{ clickable: true }}
        navigation={true}
        loop={true}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        effect="fade"
        style={{ height: '100%' }}
      >
        {images.map((image, index) => (
            <SwiperSlide key={index} style={{
            position: 'relative', // Needed for the positioning of the pseudo-element
            backgroundImage: `url(${image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            }}>
            {/* Add a pseudo-element for the overlay */}
            <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.4)', // Black with 0.25 alpha
                zIndex: 1, // Ensure it's above the image, but below the content
            }} />
            {/* Optional content that should be on top of the overlay can go here */}
            </SwiperSlide>
        ))}
        </Swiper>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        color: '#fff',
        zIndex: 2,
      }}>
        <Typography variant="h2" sx={{ mb: 2 }}>
          Look Beyond
        </Typography>
        <Typography variant="h5" sx={{ mb: 3 }}>
          {t('underSlogan')}
        </Typography>
        <Button variant="contained" sx={{ backgroundColor: '#1a76d2', '&:hover': { backgroundColor: '#155a9c' } }} onClick={
          () => {
            window.scrollTo({ top: document.getElementById('services').offsetTop, behavior: 'smooth' });
          }
        }>
          {t("askOfferButton")}
        </Button>
      </Box>
    </Box>
  );
}

export default HeroSection;
