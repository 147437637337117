import React from 'react';
import { Box, Typography, Grid, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


function AboutSection() {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // Styles for the text content
  const textStyle = {
    padding: '0 16px', // Padding around the text for narrower content width
    textAlign: isMobile ? "center" : "left", // Align text to the center on mobile and to the left on larger screens
  };

  // Styles for the image box
  const imageBoxStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '24px',
  };

  const buttonStyle = {
    marginTop: '24px', // Add some space between the text and the button
  };

  // Enhanced styles for the certification image
  // Styles for the certification image
  const certificationImageStyle = {
    borderRadius: '4px', // Softly rounded corners for a refined look
    maxWidth: '100%', // Ensure the image is responsive
    maxHeight: '250px', // Limit the height of the image
    border: '3px solid #e0e0e0', // Add a subtle border
  };

  return (
    <Box sx={{ width: '100%', backgroundColor: '#f0f0f0', padding: '48px 0' }}> {/* Full-width box with gray background */}
      <Grid container justifyContent="center" alignItems="center" sx={{ maxWidth: 1280, margin: 'auto', padding: '0 24px' }}> {/* Centered container with maximum width and padding */}
        <Grid item xs={12} md={8} sx={textStyle}>
          <Typography variant="h4" gutterBottom>
            {t('aboutTitle')} {/* "About Me" title */}
          </Typography>
          <Typography variant="body1">
            {t('aboutDesc')} {/* Description text */}
          </Typography>
          <br />
          <Typography variant="body1">
            {t('aboutDesc2')} {/* Additional description text */}
          </Typography>
          
          <Typography variant="body1">
            {t('aboutDesc3')} {/* Additional description text */}
          </Typography>

          <Button variant="contained" color="primary" sx={buttonStyle} onClick={() => {
            const contactForm = document.getElementById('contact-form');
            if (contactForm) {
                contactForm.scrollIntoView({ behavior: 'smooth' });
            }
          }}>
            {t('aboutCta')} {/* Call-to-action button text */}
          </Button>
        </Grid>
        <Grid item xs={12} md={4} sx={imageBoxStyle}>
          <Box
            component="img"
            src="/me.webp" // Path to the certification image
            alt="Me" // Alt text for the image
            sx={certificationImageStyle}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default AboutSection;
