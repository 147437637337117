import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import GalleryPage from "./Pages/GalleryPage";
import LanguageRedirect from "./comps/LanguageRedirect";
import "./i18n.js"

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/gallery" element={<GalleryPage />} />

        {/* Redirect for language paths */}
        <Route path="/nl" element={<LanguageRedirect language="nl" />} />
        <Route path="/en" element={<LanguageRedirect language="en" />} />

        {/* Redirect for language-specific gallery paths */}
        <Route path="/nl/gallery" element={<LanguageRedirect language="nl" />} />
        <Route path="/en/gallery" element={<LanguageRedirect language="en" />} />
      </Routes>
    </Router>
  );
}
export default App;