import React from "react";
import { useTranslation } from 'react-i18next';
import { useLocation, Navigate } from "react-router-dom";

const LanguageRedirect = ({ language }) => {
  const { i18n } = useTranslation();
  const location = useLocation();

  React.useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  // Determine the redirection path
  let redirectTo = '/';
  if (location.pathname.includes('/gallery')) {
    redirectTo = '/gallery';
  }

  return <Navigate to={redirectTo} />;
};

export default LanguageRedirect;